import React, { useContext } from 'react';
import { FaUser, FaPhone, FaEnvelope, FaStore, FaRegClock } from 'react-icons/fa';
import { GlobalStateContext } from '../../../context/GlobalStateContext';

const UserProfile = () => {
  const { userData } = useContext(GlobalStateContext);
  const userName = sessionStorage.getItem('userName');
  const email = sessionStorage.getItem('email');
  const phone = sessionStorage.getItem('phone');


  return (
    <div className="flex w-full flex-col gap-5 py-10 bg-gray-100 dark:bg-gray-900 min-h-screen">
      <div className="p-10 bg-white rounded-[20px] shadow-md dark:bg-gray-800 dark:text-white w-full mx-auto">
        <div className="flex items-center gap-5 mb-10">
          <FaStore className="text-blue-500 text-4xl" />
          <h1 className="text-3xl text-black-400 font-semibold">Farmacia</h1>
        </div>

        <div className="grid grid-cols-1 gap-8">

        <div className="flex items-center gap-4">
          <FaUser className="text-blue-500 text-2xl" />
            <div>
              <h2 className="text-lg font-medium">Proprietario</h2>
              <p>{userName}</p>
            </div>
          </div>

          <div className="flex items-center gap-4">
          <FaEnvelope className="text-blue-500 text-2xl" />
            <div>
              <h2 className="text-lg font-medium">Email</h2>
              <p>{email}</p>
            </div>
          </div>
          
          <div className="flex items-center gap-4">
            <FaPhone className="text-blue-500 text-2xl" />
            <div>
              <h2 className="text-lg font-medium">Telephone</h2>
              <p>{phone}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
