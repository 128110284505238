import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { AiFillAlert } from "react-icons/ai";

const Support = () => {

  const { register, handleSubmit, formState: { errors } } = useForm();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async (data) => {
    const url = window.location.href; // Capture the current URL

    try {
      await emailjs.send('service_17r6lmg', 'template_ej51b2i', {
        name: data.name,
        surname: data.surname,
        phone: data.phone,
        message: data.message,
        url: url,
      }, 'tRYU_XrWySYCTsHbg');
      setSuccessMessage('Messaggio inviato con successo!');
      setErrorMessage('');
    } catch (error) {
      console.error('Error sending email:', error);
      setErrorMessage('Errore durante l\'invio del messaggio. Per favore riprova più tardi.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="flex w-full flex-col gap-5 py-10 bg-gray-100 dark:bg-gray-900 min-h-screen">

      <div className="py-10 px-20 grid h-full grid-cols-1 gap-10 bg-white rounded-[20px] shadow-md dark:bg-grey-800 dark:text-white"> 
        <form className="my-5 space-y-6 text-black" onSubmit={handleSubmit(onSubmit)}>
          <p className='text-black-500 dark:text-white text-2xl font-semibold'>Scrivici di cosa hai bisogno...</p>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="shadow-sm -space-y-px">
            <div className="flex space-x-4 mb-5">
              <div className="w-1/2 text-black-900">
                <label htmlFor="name" className="sr-only">Nome</label>
                <input {...register("name", { required: true })} id="name" name="name" type="text" autoComplete="name" required className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Nome" />
                {errors.name && <span className="text-red-500">Il nome è obbligatorio</span>}
              </div>
              <div className="w-1/2 text-black-900">
                <label htmlFor="surname" className="sr-only">Cognome</label>
                <input {...register("surname", { required: true })} id="surname" name="surname" type="text" autoComplete="surname" required className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Cognome" />
                {errors.surname && <span className="text-red-500">Il cognome è obbligatorio</span>}
              </div>
            </div>
            <div className="space-y-5 text-black-900">
              <label htmlFor="phone" className="sr-only">Numero di Telefono</label>
              <input {...register("phone", { required: true })} id="phone" name="phone" type="tel" autoComplete="tel" required className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Numero di Telefono" />
              {errors.phone && <span className="text-red-500">Il numero di telefono è obbligatorio</span>}
            </div>
            <div className="space-y-5 text-black-900">
              <label htmlFor="message" className="sr-only">Messaggio</label>
              <textarea {...register("message", { required: true })} id="message" name="message" rows="4" className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Scrivi il tuo messaggio qui" />
              {errors.message && <span className="text-red-500">Il messaggio è obbligatorio</span>}
            </div>
          </div>
          <div className="flex justify-center items-center bg-red-100 border border-2	 border-red-500 text-red-500 py-4 rounded-lg shadow-md mb-5">
            <AiFillAlert className='text-2xl'/>
            <span className="text-lg font-semibold ms-2">Per urgenze chiamare: <a href="tel:+393334670446" className="no-underline">+39 333 4670446</a></span>
          </div>
          {successMessage && <p className="text-green-500">{successMessage}</p>}
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          <div>
            <button type="submit" className="group relative w-full flex justify-center px-8 py-2 uppercase text-white bg-blue-500 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300 ease-in-out">
              Invia
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Support;
