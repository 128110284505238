import axios from 'axios';
import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';

const ContactUsModal = ({ onClose }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async (data) => {
    const url = window.location.href; // Capture the current URL

    try {
      await emailjs.send('service_17r6lmg', 'template_ej51b2i', {
        name: data.name,
        surname: data.surname,
        phone: data.phone,
        email: data.email, // Include email in the emailjs template
        message: data.message,
        url: url,
      }, 'tRYU_XrWySYCTsHbg');
      setSuccessMessage('Messaggio inviato con successo!');
      setErrorMessage('');
    } catch (error) {
      console.error('Error sending email:', error);
      setErrorMessage('Errore durante l\'invio del messaggio. Per favore riprova più tardi.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-opacity-50 bg-gray-700 z-50 glassmorphism">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-1/3 mx-10 relative">
        <form className="my-5 space-y-6 text-black" onSubmit={handleSubmit(onSubmit)}>
          <p className='text-black-500 dark:text-white text-2xl font-semibold'>Mettiamoci in contatto!</p>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="shadow-sm -space-y-px">
            <div className="flex space-x-4 mb-5">
              <div className="w-1/2 text-black-900">
                <label htmlFor="name" className="sr-only">Nome</label>
                <input {...register("name", { required: true })} id="name" name="name" type="text" autoComplete="name" required className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Nome" />
                {errors.name && <span className="text-red-500">Il nome è obbligatorio</span>}
              </div>
              <div className="w-1/2 text-black-900">
                <label htmlFor="surname" className="sr-only">Cognome</label>
                <input {...register("surname", { required: true })} id="surname" name="surname" type="text" autoComplete="surname" required className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Cognome" />
                {errors.surname && <span className="text-red-500">Il cognome è obbligatorio</span>}
              </div>
            </div>
            <div className="space-y-5 text-black-900">
              <label htmlFor="phone" className="sr-only">Numero di Telefono</label>
              <input {...register("phone", { required: true })} id="phone" name="phone" type="tel" autoComplete="tel" required className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Numero di Telefono" />
              {errors.phone && <span className="text-red-500">Il numero di telefono è obbligatorio</span>}
            </div>
            <div className="space-y-5 text-black-900">
              <label htmlFor="email" className="sr-only">Email</label>
              <input {...register("email", { 
                required: 'L\'email è obbligatoria',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'L\'email non è valida',
                },
              })} 
                id="email" 
                name="email" 
                type="email" 
                autoComplete="email" 
                required 
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
                placeholder="Email" 
              />
              {errors.email && <span className="text-red-500">{errors.email.message}</span>}
            </div>
            <div className="space-y-5 text-black-900">
              <label htmlFor="message" className="sr-only">Messaggio</label>
              <textarea {...register("message", { required: true })} id="message" name="message" rows="4" className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Scrivi il tuo messaggio qui" />
              {errors.message && <span className="text-red-500">Il messaggio è obbligatorio</span>}
            </div>
          </div>
          {successMessage && <p className="text-green-500">{successMessage}</p>}
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          <div>
            <button type="submit" className="group relative w-full flex justify-center px-8 py-2 uppercase text-white bg-blue-oxyera border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300 ease-in-out">
              Invia
            </button>
          </div>
        </form>
        <button onClick={onClose} className="absolute right-4 top-4 text-red-500">
          <FaTimes />
        </button>
      </div>
    </div>
  );
};

export default ContactUsModal;
