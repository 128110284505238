import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import BarcodePopup from './BarcodePopup'; 
import { ImBarcode } from "react-icons/im";
import LoadingPage from 'components/loading/LoadingPage';

const OrderDetailsPopup = ({ order, onClose }) => {
  const [orderStatus, setOrderStatus] = useState(order ? order.order_status_id : '');
  const [orderDetail, setOrderDetail] = useState([]);
  const [loading, setLoading] = useState(true); // New state for loading
  const header = sessionStorage.getItem('sessionId');
  const popupDetailRef = useRef(null);
  const [selectedCF, setSelectedCF] = useState(null);
  const [selectedAIC, setSelectedAIC] = useState(null);

  const fetchTickets = useCallback(async () => {
    setLoading(true); // Set loading to true when the fetch starts
    try {
      const response = await axios.get(`https://app.oxyera.com/pharmacy/order/${order.order_id}`, 
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': header,
          },
          withCredentials: false,
        }
      );
      if (response.status === 200) {
        setOrderDetail(response.data);
      } else if (response.status === 401) {
        console.log('No token provided');
      }
    } catch (error) {
      console.log('Error during API request:', error);
    } finally {
      setLoading(false); // Set loading to false when the fetch completes
    }
  }, [order.order_id]);

  useEffect(() => {
    fetchTickets();
  }, [fetchTickets]);

  const handleClickOutside = (event) => {
    if (popupDetailRef.current && !popupDetailRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openBarcodePopupAIC = (aic) => {
    setSelectedAIC(aic); 
  };

  const openBarcodePopupCF = (cf) => {
    setSelectedCF(cf); 
  };

  const closeBarcodePopup = () => {
    setSelectedAIC(null); 
    setSelectedCF(null); 
  };

  const handleConfirmClick = async () => {
    try {
      const response = await axios.patch(`https://app.oxyera.com/pharmacy/confirm_order`, 
        { order_id: order.order_id },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': header,
          },
          withCredentials: false,
        }
      );
      if (response.status === 200) {
        setOrderStatus(2); // Update order status to 'Confermato'
        fetchTickets(); // Refresh order details
      } else if (response.status === 400) {
        console.log('No token provided');
      } else if (response.status === 401) {
        console.log('Invalid access token');
      } else if (response.status === 404) {
        console.log('Order not found');
      }
    } catch (error) {
      console.log('Error during API request:', error);
    }
  };
  
  const handleCompleteClick = async () => {
      try {
        const response = await axios.patch(`https://app.oxyera.com/pharmacy/complete_order`, 
          { order_id: order.order_id },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': header,
            },
            withCredentials: false,
          }
        );
        if (response.status === 200) {
          setOrderStatus(4);
          fetchTickets(); 
        } else if (response.status === 400) {
          console.log('No token provided');
        } else if (response.status === 401) {
          console.log('Invalid access token');
        } else if (response.status === 404) {
          console.log('Order not found');
        }
      } catch (error) {
        console.log('Error during API request:', error);
      }

  };
  

  const handleCancelClick = async () => {
    try {
      const response = await axios.patch(`https://app.oxyera.com/pharmacy/cancel_order`, 
        { order_id: order.order_id },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': header,
          },
          withCredentials: false,
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        setOrderStatus(5); // Update order status to 'Cancellato'
        fetchTickets(); // Refresh order details
      } else if (response.status === 400) {
        console.log('No token provided');
      } else if (response.status === 401) {
        console.log('Invalid access token');
      } else if (response.status === 404) {
        console.log('Order not found');
      }
    } catch (error) {
      console.log('Error during API request:', error);
    }
  };
  
  // const handleStatusChange = async () => {

  //   try {
  //       const response = await axios.post(`https://app.oxyera.com/pharmacy/confirm_order`, 
  //         { order_id: order.order_id },
  //         {
  //           headers: {
  //             'Content-Type': 'application/json',
  //             'Authorization': header,
  //           },
  //           withCredentials: false,
  //         }
  //       );
  //       if (response.status === 200) {
  //         console.log(response.data);
  //       } else if (response.status === 400) {
  //         console.log('No token provided');
  //       } else if (response.status === 401) {
  //         console.log('Invalid access token');
  //       } else if (response.status === 404) {
  //         console.log('Order not found');
  //       }
  //     } catch (error) {
  //       console.log('Error during API request:', error);
  //     }

  //   if (newStatus === '4') {
  //     try {
  //       const response = await axios.post(`https://app.oxyera.com/pharmacy/complete_order`, 
  //         { order_id: order.order_id },
  //         {
  //           headers: {
  //             'Content-Type': 'application/json',
  //             'Authorization': header,
  //           },
  //           withCredentials: false,
  //         }
  //       );
  //       if (response.status === 200) {
  //         console.log(response.data);
  //       } else if (response.status === 400) {
  //         console.log('No token provided');
  //       } else if (response.status === 401) {
  //         console.log('Invalid access token');
  //       } else if (response.status === 404) {
  //         console.log('Order not found');
  //       }
  //     } catch (error) {
  //       console.log('Error during API request:', error);
  //     }
  //   }

  //   if (newStatus === '5') {
  //     try {
  //       const response = await axios.post(`https://app.oxyera.com/pharmacy/cancel_order`, 
  //         { order_id: order.order_id },
  //         {
  //           headers: {
  //             'Content-Type': 'application/json',
  //             'Authorization': header,
  //           },
  //           withCredentials: false,
  //         }
  //       );
  //       if (response.status === 200) {
  //         console.log(response.data);
  //       } else if (response.status === 400) {
  //         console.log('No token provided');
  //       } else if (response.status === 401) {
  //         console.log('Invalid access token');
  //       } else if (response.status === 404) {
  //         console.log('Order not found');
  //       }
  //     } catch (error) {
  //       console.log('Error during API request:', error);
  //     }
  //   }
  // };

  if (!order) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full bg-opacity-50 glassmorphism z-50 overflow-y-auto">
      <div ref={popupDetailRef} className="bg-white text-black-500 p-6 rounded-lg shadow-lg w-full md:w-3/4 xl:w-2/3 mx-10 relative">
      {loading ? (
          <LoadingPage /> // Loading indicator
        ) : (
          <>
            <div className='flex justify-between items-center mb-4'>
              <h3 className="text-xl font-semibold">Dettagli Ordine #{order.order_id}</h3>

              <div className="flex flex-row items-end me-10 text-blak-500">
                <div className="">
                  
                  {orderStatus === 1 && 
                  <div className="flex flex-row items-end text-black-500">
                    <button 
                      onClick={handleConfirmClick} 
                      className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                    >
                      Conferma
                    </button>
                    <button 
                      onClick={handleCancelClick} 
                      className="bg-red-500 text-white px-4 py-2 rounded"
                    >
                      Annulla
                    </button>
                  </div>
                  }

                {orderStatus === 2 && 
                  <div className="flex flex-row items-end text-black-500">
                    <button 
                      onClick={handleCompleteClick} 
                      className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                    >
                      Completa
                    </button>
                  </div>
                }

                </div>
              </div>
              <button onClick={onClose} className="absolute right-4 top-4 text-red-500">
                <FaTimes />
              </button>
            </div>
            <div className="grid grid-cols-1">
              <div className='overflow-x-auto'>
                <p className='capitalize'><strong>Nome Paziente:</strong> {order.patient_lastname} {order.patient_firstname}</p>
                <p className='uppercase flex flex-row'><strong className='capitalize'>Codice Fiscale:</strong> 
                  <button onClick={() => openBarcodePopupCF(order.patient_fiscal_code)} className="ml-2 text-blue-500 flex flex-row items-center gap-4">
                    {order.patient_fiscal_code || 'N/A'} 
                    {order.patient_fiscal_code && (
                      <ImBarcode className='ms-1 text-xl'/>
                    )}
                  </button>
                </p>
                <p><strong>ID Ordine:</strong> {order.order_id}</p>
                <p><strong>Tipo Ordine:</strong> {order.order_type_description}</p>
                <p><strong>Prezzo:</strong> {order.order_price} €</p>
                <p><strong>Quantità:</strong> {order.items}</p>
                <p><strong>Data Ritiro:</strong> {new Date(order.order_datetime).toLocaleDateString('en-GB')}</p>
                <p><strong>Stato Ordine:</strong> 
                    {orderStatus === 1 && <span className="text-yellow-500"> Richiesto</span>}
                    {orderStatus === 2 && <span className="text-blue-500"> Confermato</span>}
                    {orderStatus === 4 && <span className="text-green-500"> Completato</span>}
                    {orderStatus === 5 && <span className="text-red-500"> Cancellato</span>}
                    {orderStatus === 3 && <span className="text-blue-500"> Spedito</span>}
                </p>

                
                <table className="min-w-full text-black mt-6 mb-4">
                  <thead>
                    <tr className="bg-black-50 text-black-500">
                      <th className="py-2 px-4 border border-black-500 text-center cursor-pointer text-start">Nome Farmaco</th>
                      <th className="py-2 px-4 border border-black-500 text-center cursor-pointer text-start">Descrizione</th>
                      <th className="table-cell py-2 px-4 border border-black-500 text-center cursor-pointer text-start">AIC</th>
                      <th className="table-cell py-2 px-4 border border-black-500 text-center cursor-pointer text-start">Azienda</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderDetail.map((detail, index) => (
                      <tr key={index} className="">
                        <td className="py-2 px-4 border border-black-500 text-start">
                          {detail.medicine_drug ? detail.medicine_drug.split('*')[0].trim() : 'N/A'}
                        </td>
                        <td className="py-2 px-4 border border-black-500 text-start">
                          {detail.medicine_drug ? detail.medicine_drug.split('*')[1]?.trim() : 'N/A'}
                        </td>
                        <td className="py-2 px-4 border border-black-500 text-start">
                          <div className='flex justify-start items-center'>
                            <button onClick={() => openBarcodePopupAIC(detail.medicine_aic)} className="ml-2 text-blue-500 flex flex-row items-center gap-4">
                              {detail.medicine_aic || 'N/A'} 
                              {detail.medicine_aic && (
                                <ImBarcode className='ms-1 text-xl'/>
                              )}
                            </button>
                          </div>
                        </td>
                        <td className="py-2 px-4 border border-black-500 text-start">
                          {detail.medicine_company || 'N/A'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        {selectedAIC && (
          <BarcodePopup aicCode={selectedAIC} cfCode={null} onClose={closeBarcodePopup} />
        )}
        {selectedCF && (
          <BarcodePopup cfCode={selectedCF} aicCode={null} onClose={closeBarcodePopup} />
        )}
      </div>
    </div>
  );
};

export default OrderDetailsPopup;
